import Banner from '../components/Banner';

const Home = () => {
  return (
    <div className='home'>
      <Banner />
    </div>
  );
};

export default Home;
