import { useState } from "react";
import { useSignUp } from "../hooks/useSignUp";


const Signup = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {signup, error, isLoading} = useSignUp()
    
    const handleSignUp = async(e) => {
        e.preventDefault()

        await signup(email, password)

    }

    return (
        <form className='signup' onSubmit={handleSignUp}>
            <h3>Sign Up</h3>

            <label>Email:</label>
            <input 
                type="email" 
                onChange={(e) => {setEmail(e.target.value)}}
                value={email}   
                required={true}
            />

            <label>Password:</label>
            <input 
                type="password" 
                onChange={(e) => {setPassword(e.target.value)}}
                value={password}   
                required={true}
            />
            <button disabled={isLoading}>Sign Up</button>
            {error && <div className='error'>{error}</div>}
        </form>
    );
}
 
export default Signup;